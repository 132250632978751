<template>
    <div class="audio">
        <div class="tabHeader">
            <div class="tab">
                <button
                    v-for="tab in tabs"
                    :key="tab.id"
                    @click="selectTab(tab.id)"
                    :class="{ active: activeTab === tab.id }">
                    {{ tab.title }}
                </button>
            </div>
            <div class="found">
                <!-- 添加克隆声音的点击事件 -->
                <button @click.prevent="confirmCloning">克隆声音</button>
            </div>
        </div>

        <!-- 我的声音 -->
        <div v-if="activeTab === 'myVoice'">
            <myVoice
                ref="child"
                @myIndex="updateIndex('myVoice', $event)"
                @endAudio="resetAudio"
                :list="myVoiceList" />
            <div v-if="myTotal > pageSize" class="paging">
                <el-pagination
                    background
                    layout="total, sizes, prev, pager, next, jumper"
                    :current-page="myPage"
                    :page-size="pageSize"
                    :total="myTotal"
                    @current-change="loadData('myVoice')" />
            </div>
        </div>

        <!-- 公共声音 -->
        <div v-if="activeTab === 'comVoice'">
            <publicVoice
                ref="child"
                @comIndex="updateIndex('comVoice', $event)"
                @endAudio="resetAudio"
                :list="commonalityList" />
            <div v-if="comTotal > pageSize" class="paging">
                <el-pagination
                    background
                    layout="total, sizes, prev, pager, next, jumper"
                    :current-page="comPage"
                    :page-size="pageSize"
                    :total="comTotal"
                    @current-change="loadData('comVoice')" />
            </div>
        </div>
    </div>
</template>

<script>
import { MessageBox, Message } from 'element-ui';
import myVoice from '../../components/voiceModule/myVoice.vue';
import publicVoice from '../../components/voiceModule/commonalityVoice.vue';
import { getCommonsoundModel, getMysoundModel } from '../../api/api';

export default {
    components: {
        myVoice,
        publicVoice,
    },
    data() {
        return {
            activeTab: 'myVoice', // 当前选中的标签
            tabs: [
                { id: 'myVoice', title: '我的声音' },
                { id: 'comVoice', title: '公共声音' },
            ],
            myVoiceList: [],
            commonalityList: [],
            myTotal: 0,
            comTotal: 0,
            myPage: 1,
            comPage: 1,
            pageSize: 15, // 每页显示的数量
            loading: false, // 加载状态
        };
    },
    methods: {
        /**
         * 切换标签
         * @param {String} tabId - 选中的标签 ID
         */
        selectTab(tabId) {
            this.activeTab = tabId;
            this.resetAudio();
            this.loadData(tabId);
        },
        /**
         * 更新选中的音频索引
         * @param {String} type - 类型（myVoice 或 comVoice）
         * @param {Number} index - 索引
         */
        updateIndex(type, index) {
            if (type === 'myVoice') {
                this.myVoiceList.forEach((item, i) => (item.show = i === index));
            } else {
                this.commonalityList.forEach((item, i) => (item.show = i === index));
            }
        },
        /**
         * 重置音频播放状态
         */
        resetAudio() {
            this.myVoiceList.forEach(item => (item.show = false));
            this.commonalityList.forEach(item => (item.show = false));
        },
        /**
         * 通用数据加载方法
         * @param {String} type - 类型（myVoice 或 comVoice）
         */
        loadData(type) {
            if (this.loading) return; // 防止重复加载
            this.loading = true;
            const page = type === 'myVoice' ? this.myPage : this.comPage;
            const apiFunc = type === 'myVoice' ? getMysoundModel : getCommonsoundModel;

            apiFunc({ page, page_size: this.pageSize })
                .then(res => {
                    if (res.data.code === 10000) {
                        const data = res.data.data.map(item => ({ ...item, show: false }));
                        if (type === 'myVoice') {
                            this.myVoiceList = data;
                            this.myTotal = res.data.total;
                        } else {
                            this.commonalityList = data;
                            this.comTotal = res.data.total;
                        }
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        /**
         * 提示用户克隆声音的确认框
         */
        confirmCloning() {
            MessageBox.confirm(
                '克隆一个角色音色将消耗 12000 积分，永久使用。是否继续？',
                '提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    // 用户确认克隆操作
                    this.cloneSound();
                })
                .catch(() => {
                    // 用户取消操作
                    Message({
                        type: 'info',
                        message: '已取消克隆操作',
                    });
                });
        },
        /**
         * 执行克隆声音的逻辑
         */
        cloneSound() {
            // 此处调用克隆声音的 API 或执行对应逻辑
            this.$router.push('/SoundCloning'); // 跳转到克隆页面
            Message({
                type: 'success',
                message: '正在处理克隆请求，请稍候...',
            });
        },
    },
    created() {
        this.loadData('myVoice'); // 初始化加载我的声音
        this.loadData('comVoice'); // 初始化加载公共声音
    },
};
</script>

<style lang="less" scoped>
.audio {
    width: 100%;
    max-width: 1540px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;

    .tabHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tab button {
            background: none;
            border: none;
            padding: 15px 0;
            font-size: 18px;
            margin-right: 20px;
            cursor: pointer;
            transition: color 0.3s, border-bottom 0.3s;
            border-bottom: 2px solid transparent;
            color: #939393;

            &.active {
                font-weight: bold;
                border-bottom: 2px solid #000;
                color: #000;
            }
        }

        .found button {
            cursor: pointer;
            padding: 10px 20px;
            background-color: #4589f1;
            border: none;
            color: #fff;
            border-radius: 5px;
        }
    }

    .paging {
        margin-top: 20px;
        text-align: center;
    }
}
</style>
