<template>
    <!-- 文本驱动合成音频弹出框 -->
    <div class="audioPopup">
        <!-- 选择声音模型 -->
        <div class="selectionModel">
            <div class="tabHeader">
                <div v-for="tab in tabs" :key="tab.id" @click="selectTab(tab.id)"
                    :class="{ active: activeTab === tab.id }">
                    {{ tab.title }}
                </div>
            </div>
            <div class="audioList">
                <div class="item">
                    <audioMini ref="child" :list="list" @audioId="audioId" />
                </div>
            </div>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination layout="prev, pager, next" :current-page.sync="page" :page-size="8" :total="total"
                    @current-change="handlePageChange">
                </el-pagination>
            </div>
        </div>
        <div class="more">
            <!-- 文本框 -->
            <div class="textarea">
                <el-input type="textarea" maxlength="1000" placeholder="请输入内容" v-model="setData.content"
                    show-word-limit>
                </el-input>
            </div>
        </div>
        <div slot="footer" class="dialog-footer" style="margin-top: 20px; display: flex; justify-content: right;">
            <el-button type="primary" @click="submit">确 定</el-button>
        </div>
    </div>
</template>
<script>
import audioMini from '../../../components/audioMini.vue'
import { getMysoundModel, getCommonsoundModel, textToAudio } from '../../../api/api'
export default {
    components: {
        audioMini
    },
    props: {
        audioPopShow: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            activeTab: 'mine', // 选中tab的ID
            tabs: [{ id: 'mine', title: '我的' }, { id: 'com', title: '公共' }],

            list: [], // 音频列表
            page: 1, // 页数
            total: 0, // 音频总数

            // 提交的数据
            setData: {
                model_id: '', // 组件传过来的选中的音频的id
                content: ''
            },
        }
    },
    methods: {
        // 选项卡
        selectTab(id) {
            this.activeTab = id
            this.$refs.child.pause()
            this.setData.model_id = ''
            this.$refs.child.itemIndex = -1
            this.page = 1
            this.list = []

            if (this.activeTab === 'mine') {
                this.getMysoundModel()
            }
            if (this.activeTab === 'com') {
                this.getCommonsoundModel()
            }
        },
        // 组件传过来的选中的音频的id
        audioId(e) {
            this.setData.model_id = e
        },
        // 分页
        handlePageChange(page) {
            this.page = page
            this.list = []
            if (this.activeTab === 'mine') {
                this.getMysoundModel()
            }
            if (this.activeTab === 'com') {
                this.getCommonsoundModel()
            }
        },
        // 提交
        submit() {
            if (!this.setData.model_id) {
                return this.$message({ message: '请选择声音模型', type: 'warning' })
            }
            if (!this.setData.content) {
                return this.$message({ message: '请输入需要合成的话术', type: 'warning' })
            }

            const loading = this.$loading({
                lock: true,
                text: '上传中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.5)'
            })

            textToAudio({ ...this.setData }).then(res => {
                loading.close()
                if (res.data.code === 10000) {
                    this.$message({ message: '提交成功', type: 'success' })
                    this.resetForm()
                    this.$emit('popup', false)
                }
            })
        },
        // 重置表单
        resetForm() {
            this.setData = {
                model_id: '',
                content: ''
            }
            this.$refs.child.itemIndex = -1
        },
        // 获取我的声音模型
        getMysoundModel() {
            const params = { page: this.page, page_size: 8 }
            getMysoundModel(params).then(res => {
                if (res.data.code === 10000) {
                    this.list = res.data.data
                    this.total = res.data.total
                }
            })
        },
        // 获取公共声音模型
        getCommonsoundModel() {
            const params = { page: this.page, page_size: 8 }
            getCommonsoundModel(params).then(res => {
                if (res.data.code === 10000) {
                    this.list = res.data.data
                    this.total = res.data.total
                }
            })
        }
    },
    created() {
        this.getMysoundModel()
    }
}
</script>
<style lang="less" scoped>
.selectionModel {
    .tabHeader {
        display: flex;
        background-color: #fff;
        margin-bottom: 10px;
    }

    .audioList {
        display: flex;
        flex-wrap: wrap;
        min-height: 145px;
        margin-bottom: 10px;

        .item {
            margin-top: 10px;
        }
    }

    .tabHeader div {
        width: 80px;
        height: 38px;
        border: 1px solid #ccc;
        font-size: 16px;
        text-align: center;
        line-height: 38px;
        margin-right: 10px;
        cursor: pointer;
    }

    .tabHeader div.active {
        background-color: #279CEF;
        color: #fff;
        border: none;
    }

    .paging {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }
}

.textarea {
    width: 100%;
    margin-bottom: 20px;

    /deep/.el-textarea__inner {
        resize: none;
        border: 1px solid #ccc;
		height: 250px !important; /* 强制设置内部高度 */
        line-height: 1.6; /* 可选：调整行高，优化多行文本显示 */
    }
}

.dialog-footer {
    display: flex;
    justify-content: flex-end;
}
</style>
